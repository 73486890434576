import Manufacturer from './Manufacturer';
import Taste from './Taste';

export class Tobacco {
	id!: number;
	name!: string;
	manufacturer!: Manufacturer;
	tastes: Taste[] = [];
	ean!: string;

	constructor(jsonObject: any) {
		Object.keys(jsonObject).forEach((key) => {
			switch (key) {
				case 'id':
					this.id = jsonObject[key];
					return;
				case 'name':
					this.name = jsonObject[key];
					return;
				case 'manufacturer':
					this.manufacturer = new Manufacturer(jsonObject[key]);
					return;
				case 'taste':
					jsonObject[key].forEach((tasteObj: any) => {
						this.tastes.push(new Taste(tasteObj));
					});
					return;
				case 'ean':
					this.ean = jsonObject[key];
					return;
			}
		});
	}
}

export default Tobacco;
