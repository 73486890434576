import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BottomBar from '../Other/BottomBar/BottomBar';
import HeaderBar from '../Other/HeaderBar/HeaderBar';
import Homescreen from './Homescreen/Homescreen';
import Recommendationscreen from './Recommendationscreen/Recommendationscreen';
import Searchscreen from './Searchscreen/Searchscreen';
import AddSmokedTobaccoDialog from '../Other/AddSmokedTobacco/AddSmokedTobacco';
import User from '../../Models/User';
import APIController from '../../api/APIController';
import SmokedTobacco from '../../Models/SmokedTobacco';

interface IProps {
	apiController: APIController;
}

let lastScrollY = 0;

const Mainscreen: React.FC<IProps> = (props) => {
	const [activeScreen, setActiveScreen] = useState(Homescreen.name);
	const [openAddDialog, setOpenAddDialog] = useState(false);
	const [user, setUser] = useState<User | null>(null);

	const [showBottomBar, setShowBottomBar] = useState(true);

	const listenScrollEvent = () => {
		if (window.scrollY == 0) {
			// If the window isn't scrolled down, just show the bottombar
			setShowBottomBar(true);
			return;
		}

		if (window.scrollY >= lastScrollY) setShowBottomBar(false);
		else setShowBottomBar(true);

		lastScrollY = window.scrollY;
	};

	useEffect(() => {
		window.addEventListener('scroll', listenScrollEvent);
		window.addEventListener('resize', listenScrollEvent);
		window.addEventListener('touchmove', listenScrollEvent); // For mobile purposes
		return () => {
			window.removeEventListener('scroll', listenScrollEvent);
			window.removeEventListener('resize', listenScrollEvent);
			window.removeEventListener('touchmove', listenScrollEvent); // For mobile purposes
		};
	}, []);

	//If screen was changed, recalculate wheter the bottombar should be shown or not
	useEffect(listenScrollEvent, [activeScreen]);

	/**
	 *
	 * @returns Gives back the active screen, based on the users choice of bottombar
	 */
	const getActiveScreen = () => {
		switch (activeScreen) {
			case Homescreen.name:
				return <Homescreen apiController={props.apiController} />;

			case Recommendationscreen.name:
				return <Recommendationscreen />;

			case Searchscreen.name:
				return <Searchscreen />;
		}
	};

	const handleClickRecommendationscreen = () => {
		console.log('Changing to screen ' + Recommendationscreen.name);
		setActiveScreen(Recommendationscreen.name);
	};

	const handleClickHomescreen = () => {
		console.log('Changing to screen ' + Homescreen.name);
		setActiveScreen(Homescreen.name);
	};

	const handleClickSearchscreen = () => {
		console.log('Changing to screen ' + Searchscreen.name);
		setActiveScreen(Searchscreen.name);
	};

	const handleOpenAddDialog = () => {
		setOpenAddDialog(true);
	};

	const handleCloseAddDialog = () => {
		setOpenAddDialog(false);
	};

	const addSmokedTobacco = (smokedTobacco: SmokedTobacco) => {
		// TODO: show added tobacco immediately
	};

	useEffect(() => {
		props.apiController.getUserInfo(setUser);
	}, []);

	return (
		<>
			<HeaderBar username={user != null ? user.username : ''} />
			{getActiveScreen()}
			<BottomBar
				isVisible={showBottomBar}
				handleOpenAddDialog={handleOpenAddDialog}
				handleClickHomescreen={handleClickHomescreen}
				handleClickRecommendationscreen={handleClickRecommendationscreen}
				handleClickSearchscreen={handleClickSearchscreen}
			/>
			<Dialog open={openAddDialog} fullWidth={true}>
				<AddSmokedTobaccoDialog
					addSmokeTobacco={addSmokedTobacco}
					handleClose={handleCloseAddDialog}
					apiController={props.apiController}
				/>
			</Dialog>
		</>
	);
};

export default Mainscreen;
