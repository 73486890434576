import Tobacco from './Tobacco';

export class SmokedTobacco {
	id!: number;
	tobacco!: Tobacco;
	smokedOn!: Date;

	/**
	 *
	 * @param jsonObject smokedTobacco as a json (like from API)
	 */
	constructor(jsonObject: any) {
		Object.keys(jsonObject).forEach((key) => {
			switch (key) {
				case 'id':
					this.id = jsonObject[key];
					return;
				case 'tobacco':
					this.tobacco = new Tobacco(jsonObject[key]);
					return;
				case 'smoked_on':
					this.smokedOn = new Date(jsonObject[key]);
					return;
			}
		});
	}

	/**
	 * Convert object into JSON
	 */
	serialize(): string {
		return '';
	}

	/**
	 * Convert JSON into object
	 */
	deserialize(json: any): void {
		Object.keys(json).forEach((key) => {
			switch (key) {
				case 'id':
					this.id = json[key];
					return;
				case 'tobacco':
					this.tobacco = new Tobacco(json[key]);
					return;
				case 'smoked_on':
					this.smokedOn = new Date(json[key]);
					return;
			}
		});
	}
}

export default SmokedTobacco;
