import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import React from 'react';

interface IProps {
	username?: string;
}
/**
 * Creates the headerbar for every page
 * @param {string} username The username which is displayed in the right. Don't pass, if it shouldn't show
 * @returns AppBar
 */
const HeaderBar: React.FC<IProps> = (props) => {
	return (
		<AppBar position='sticky'>
			<Toolbar>
				<Typography variant='h6' fontWeight='bold' sx={{ flexGrow: 1 }}>
					HookahCentral
				</Typography>
				<Box flexGrow={1} />
				{props.username != null && (
					<Box
						display='flex'
						flexDirection='column'
						color='black'
						textAlign='start'
						border={1}
						borderRadius={2.25}
						padding={0.3}
						sx={{ backgroundColor: 'white' }}
					>
						<Typography fontStyle='italic'>Willkommen,</Typography>
						<Typography variant='h6' fontStyle='italic' fontWeight='bold'>
							{props.username}
						</Typography>
					</Box>
				)}
			</Toolbar>
		</AppBar>
	);
};

export default HeaderBar;
