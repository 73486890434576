import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import { Autocomplete, AutocompleteValue, IconButton } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import { matchSorter } from 'match-sorter';
import * as React from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import APIController from '../../../api/APIController';
import SmokedTobacco from '../../../Models/SmokedTobacco';
import Tobacco from '../../../Models/Tobacco';

interface IProps {
	apiController: APIController;
	handleClose: Function;
	addSmokeTobacco: Function;
}

const AddSmokedTobacco: React.FC<IProps> = (props) => {
	const [selectedTobacco, setSelectedTobacco] = React.useState<Tobacco>();
	const [selectedDate, setSelectedDate] = React.useState(new Date());
	const [tobaccos, setTobaccos] = React.useState<Tobacco[]>([]);
	const [isLoadingNewTobaccos, setIsLoadingNewTobaccos] = React.useState(false);

	const onGetTobaccosSuccess = (jsonTobaccos: string[]) => {
		let newTobaccos = tobaccos;

		jsonTobaccos.forEach((jsonTobacco) => {
			const tobacco = new Tobacco(jsonTobacco);
			newTobaccos.push(tobacco);
		});

		setTobaccos(newTobaccos);
		setIsLoadingNewTobaccos(false);
	};

	useEffect(() => {
		console.log(tobaccos);
	}, [tobaccos]);

	const onGetTobaccosError = (response: Response) => {
		console.log(response);
	};

	useEffect(() => {
		setIsLoadingNewTobaccos(true);
		props.apiController.getTobaccos(onGetTobaccosSuccess, onGetTobaccosError);
	}, []);

	const onChangeTobacco = (event: React.SyntheticEvent, value: any | null) => {
		setSelectedTobacco(value);
	};

	const onChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
		console.log(new Date(event.currentTarget.value));
		setSelectedDate(new Date(event.currentTarget.value));
	};

	const submitData = () => {
		console.log(selectedTobacco);
		if (!selectedTobacco) {
			toast.warn('Bitte alle Felder ausfüllen!', {
				position: 'bottom-center',
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
			return;
		} else {
			let body = {
				smoked_on: selectedDate.toUTCString(),
				tobacco_id: selectedTobacco.id,
			};

			props.apiController.postSmokedTobacco(
				body,
				(smokedTobacco: SmokedTobacco) => {
					console.log('SUCESSFULLY ADDED SMOKED TOBACCO');
					console.log(smokedTobacco);
				},
				(error: string) => {
					console.log('ERROR');
					console.log(error);
				}
			);

			props.handleClose();
		}
	};

	const filterOptions = (
		options: Tobacco[],
		{ inputValue }: { inputValue: string }
	) => {
		console.log('Options:');
		console.log(options);
		console.log('input: ' + inputValue);
		return matchSorter(options, inputValue.trim(), {
			keys: ['manufacturer.name', 'name'],
		});
	};

	return (
		<>
			<DialogTitle sx={{ fontWeight: 'bold' }}>Gerauchter Tabak</DialogTitle>
			<DialogContent sx={{ minWidth: 250, minHeight: 350 }}>
				<Autocomplete
					fullWidth={true}
					autoComplete={true}
					disablePortal
					ListboxProps={{ style: { maxHeight: '15rem' } }}
					loading={isLoadingNewTobaccos}
					sx={{ marginTop: 2 }}
					options={tobaccos.sort((a, b) => {
						return a.manufacturer.name.localeCompare(b.manufacturer.name);
					})}
					getOptionLabel={(tobacco: Tobacco) => tobacco.name}
					groupBy={(tobacco: Tobacco) => tobacco.manufacturer.name}
					onChange={onChangeTobacco}
					filterOptions={filterOptions}
					renderInput={(params) => {
						return (
							<TextField
								{...params}
								label='Tabak'
								// InputProps={{
								// 	...params.InputProps,
								// 	endAdornment: (
								// 		<React.Fragment>
								// 			{isLoadingNewTobaccos ? (
								// 				<CircularProgress color='inherit' size={20} />
								// 			) : null}
								// 			{params.InputProps.endAdornment}
								// 		</React.Fragment>
								// 	),
								// }}
							/>
						);
					}}
				/>
				<TextField
					fullWidth={true}
					id='datetime-local'
					label='Geraucht am'
					type='datetime-local'
					defaultValue={new Date()
						.toISOString()
						.substring(0, new Date().toISOString().length - 8)}
					onChange={onChangeDate}
					sx={{ marginTop: 2 }}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</DialogContent>
			<DialogActions>
				<IconButton onClick={() => props.handleClose()} color='error'>
					<CancelIcon fontSize='large' />
				</IconButton>
				<Box flexGrow={1} />
				<IconButton onClick={submitData} color='primary'>
					<CheckIcon fontSize='large' />
				</IconButton>
			</DialogActions>
		</>
	);
};

export default AddSmokedTobacco;
