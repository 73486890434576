import { AppBar, Box, Dialog, Fab, IconButton, Toolbar } from '@mui/material';
import React, { MouseEventHandler } from 'react';

import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

interface IProps {
	isVisible: boolean;
	handleOpenAddDialog: MouseEventHandler;
	handleClickHomescreen: MouseEventHandler;
	handleClickRecommendationscreen: MouseEventHandler;
	handleClickSearchscreen: MouseEventHandler;
}

const BottomBar = (props: IProps) => {
	return (
		<div>
			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: `${props.isVisible ? '0' : '-100px'}`,
					transition: `bottom 1.5s`,
				}}
			>
				<Toolbar>
					<Box sx={{ flexGrow: 0.2 }} />
					<Fab
						sx={{
							position: 'absolute',
							top: -30,
							margin: '0 auto',
							left: 0,
							right: 0,
						}}
						color='secondary'
						aria-label='Add'
						onClick={props.handleOpenAddDialog}
					>
						<AddIcon />
					</Fab>
					<IconButton
						color='inherit'
						size='large'
						aria-label='Home'
						onClick={props.handleClickHomescreen}
					>
						<HomeIcon fontSize='large' />
					</IconButton>
					<Box sx={{ flexGrow: 0.75 }} />
					<IconButton
						color='inherit'
						size='large'
						aria-label='Stats'
						onClick={props.handleClickRecommendationscreen}
					>
						<AutoGraphIcon fontSize='large' />
					</IconButton>
					<IconButton
						color='inherit'
						size='large'
						aria-label='search'
						onClick={props.handleClickSearchscreen}
					>
						<ManageSearchIcon fontSize='large' />
					</IconButton>
				</Toolbar>
			</AppBar>
		</div>
	);
};

export default BottomBar;
