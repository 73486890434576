interface IManufacturer {
	id: number;
	name: string;
}

export class Manufacturer implements IManufacturer {
	id!: number;
	name!: string;

	constructor(jsonObject: any) {
		Object.keys(jsonObject).forEach((key) => {
			switch (key) {
				case 'id':
					this.id = jsonObject[key];
					return;
				case 'name':
					this.name = jsonObject[key];
					return;
			}
		});
	}
}

export default Manufacturer;
