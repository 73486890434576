import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardHeader,
	Collapse,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import React, { useReducer } from 'react';
import APIController from '../../../../api/APIController';
import SmokedTobacco from '../../../../Models/SmokedTobacco';

interface IProps {
	smokedTobacco: SmokedTobacco;
	apiController: APIController;
	removeSmokedTobacco: Function;
}

enum IExpanderActionTypes {
	OPENING = 'OPENING',
	SWAPPING = 'SWAPPING',
	CLOSING = 'CLOSING',
}

interface IExpanderState {
	isExpanded: boolean;
	expandIcon: any; //TODO: what's the correct type??
}

interface IExpanderAction {
	type: IExpanderActionTypes;
	payload?: string | number | boolean;
}

const initialExpanderState: IExpanderState = {
	isExpanded: false,
	expandIcon: <ExpandMoreIcon aria-label='test' />,
};

const expanderReducer = (
	state: IExpanderState,
	action: IExpanderAction
): IExpanderState => {
	switch (action.type) {
		case IExpanderActionTypes.OPENING:
			return { ...state, isExpanded: true, expandIcon: ExpandLessIcon };
		case IExpanderActionTypes.CLOSING:
			return { ...state, isExpanded: false, expandIcon: ExpandMoreIcon };
		case IExpanderActionTypes.SWAPPING:
			const swappedIcon = state.isExpanded ? (
				<ExpandMoreIcon />
			) : (
				<ExpandLessIcon />
			);
			return {
				...state,
				isExpanded: !state.isExpanded,
				expandIcon: swappedIcon,
			};
		default:
			return { ...state };
	}
};

const SmokedTobaccoCard: React.FC<IProps> = (props) => {
	const [expander, dispExpander] = useReducer(
		expanderReducer,
		initialExpanderState
	);
	const { isExpanded, expandIcon } = expander;

	return (
		<Card
			elevation={8}
			sx={{
				margin: 0,
				padding: 1,
				borderRadius: 3,
			}}
		>
			<CardContent style={{ margin: '0px' }}>
				<Grid container>
					<Grid item xs={9}>
						<Typography variant='h6' align='left' fontWeight='bold'>
							{props.smokedTobacco.tobacco.name}
						</Typography>
						<Typography align='left' fontStyle='italic'>
							{props.smokedTobacco.tobacco.manufacturer.name}
						</Typography>
					</Grid>
					<Grid item xs={2}>
						{props.smokedTobacco.smokedOn != null && (
							<>
								<Typography>
									{props.smokedTobacco.smokedOn.toLocaleDateString('de-DE', {
										day: '2-digit',
									})}
								</Typography>
								<Typography>
									{props.smokedTobacco.smokedOn.toLocaleDateString('de-DE', {
										month: 'short',
									})}
								</Typography>
							</>
						)}
					</Grid>
					<Grid item xs={1}>
						<IconButton
							color='primary'
							style={{ height: '100%' }}
							onClick={() => {
								dispExpander({
									type: IExpanderActionTypes.SWAPPING,
								});
							}}
						>
							{expandIcon}
						</IconButton>
					</Grid>
				</Grid>
			</CardContent>
			<Collapse in={isExpanded}>
				<CardContent>
					{props.smokedTobacco.tobacco.tastes.map((taste) => {
						return (
							<Typography align='left' key={taste.id} width='50%'>
								{taste.name}
							</Typography>
						);
					})}
				</CardContent>
				<CardActions
					style={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<IconButton
						sx={{ float: 'left' }}
						onClick={() => {
							console.log('Clicked Deletebutton');
							props.apiController.deleteSmokedTobacco(props.smokedTobacco.id);
							props.removeSmokedTobacco(props.smokedTobacco.id);
						}}
					>
						<DeleteIcon />
					</IconButton>
					<IconButton
						sx={{ float: 'right' }}
						onClick={() => {
							console.log('Clicked Editbutton');
						}}
					>
						<EditIcon />
					</IconButton>
				</CardActions>
			</Collapse>
		</Card>
	);
};

export default SmokedTobaccoCard;
