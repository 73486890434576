import {
	Button,
	Card,
	CardContent,
	CardHeader,
	TextField,
} from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';
import APIController from '../../../api/APIController';
import HeaderBar from '../../Other/HeaderBar/HeaderBar';

type ReducerAction = {
	type: string;
	field: string;
	payload: any;
};

enum ReducerActionTypes {
	onInputChange = 'Input-Change',
	onClickLogin = 'Login-Start',
	isFormValid = 'IsFormValid',
}

type ReducerState = {
	email: string;
	password: string;
	isFormValid: boolean;
	isLoading: boolean;
	isLoggedIn: boolean;
	isError: boolean;
};

const reducerFunction = (
	state: ReducerState,
	action: ReducerAction
): ReducerState => {
	switch (action.type) {
		case ReducerActionTypes.onInputChange:
			return { ...state, [action.field]: action.payload };
		case ReducerActionTypes.onClickLogin:
			return { ...state, isLoading: true };
		case ReducerActionTypes.isFormValid:
			return { ...state, isFormValid: action.payload };
		default:
			return { ...state };
	}
};

const initialState: ReducerState = {
	email: '',
	password: '',
	isFormValid: false,
	isLoggedIn: false,
	isError: false,
	isLoading: false,
};

interface IProps {
	apiController: APIController;
}

const Loginscreen: React.FC<IProps> = (props) => {
	const [state, dispatchFunction] = useReducer(reducerFunction, initialState);

	useEffect(() => {
		if (
			state.password.length > 7 &&
			state.email.includes('@') &&
			state.email.includes('.')
		)
			dispatchFunction({
				type: ReducerActionTypes.isFormValid,
				field: '',
				payload: true,
			});
		else
			dispatchFunction({
				type: ReducerActionTypes.isFormValid,
				field: '',
				payload: false,
			});
	}, [state.password, state.email]);

	const onClickLogin = () => {
		if (state.isFormValid) {
			props.apiController.login(state.email, state.password);
		}
	};

	return (
		<>
			<HeaderBar />
			<Card
				elevation={8}
				sx={{
					margin: '3%',
					marginTop: '3%',
				}}
			>
				<CardHeader title='Login'></CardHeader>
				<CardContent>
					<TextField
						label='E-Mail'
						autoFocus={true}
						fullWidth={true}
						onChange={(event) => {
							dispatchFunction({
								type: ReducerActionTypes.onInputChange,
								field: 'email',
								payload: event.currentTarget.value,
							});
						}}
						sx={{ margin: '3 3 3 3' }}
					></TextField>
					<TextField
						label='Passwort'
						fullWidth={true}
						type='password'
						onChange={(event) => {
							dispatchFunction({
								type: ReducerActionTypes.onInputChange,
								field: 'password',
								payload: event.currentTarget.value,
							});
						}}
						sx={{ marginTop: 1 }}
					></TextField>
					<Button
						variant='contained'
						fullWidth={true}
						disabled={!state.isFormValid}
						onClick={onClickLogin}
						sx={{ marginTop: 5, borderRadius: 5 }}
					>
						Login
					</Button>
				</CardContent>
			</Card>
		</>
	);
};

export default Loginscreen;
