import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Card,
	CardActionArea,
	CardContent,
	CardHeader,
	Grid,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import APIController from '../../../api/APIController';
import SmokedTobacco from '../../../Models/SmokedTobacco';
import SmokedTobaccoCard from './SmokedTobaccoCard/SmokedTobaccoCard';

interface IProps {
	apiController: APIController;
}

const LastSmokedTobaccoCard: React.FC<IProps> = (props) => {
	const [isLoadingNewTobacco, setIsLoadingNewTobacco] = useState(false);
	const [tobaccoSiteNr, setTobaccoSiteNr] = useState(1);
	const [lastSmokedTobaccos, setLastSmokedTobaccos] = useState<SmokedTobacco[]>(
		[]
	);

	/**
	 * Removes the specific SmokedTobacco from the list
	 * @param id It's the id from the smokedTobacco, not the indexnumber of the array
	 */
	const removeSmokedTobacco = (id: number) => {
		const newLastST = lastSmokedTobaccos.filter((st) => {
			return st.id !== id;
		});

		setLastSmokedTobaccos(newLastST);
	};

	const saveSmokedTobacco = (jsonTobaccos: string[]) => {
		let smokedTobaccos: SmokedTobacco[] = lastSmokedTobaccos;

		jsonTobaccos.forEach((jsonTobacco) => {
			const smokedTobacco = new SmokedTobacco(jsonTobacco);
			smokedTobaccos.push(smokedTobacco);
		});

		setLastSmokedTobaccos(smokedTobaccos);
		setIsLoadingNewTobacco(false);
	};

	const loadMoreTobacco = () => {
		setIsLoadingNewTobacco(true);
		props.apiController.getSmokedTobacco(tobaccoSiteNr, saveSmokedTobacco);
	};

	useEffect(() => {
		console.log('########## lastSmokedTobaccos was updated');
		console.log(lastSmokedTobaccos);
	}, [lastSmokedTobaccos]);

	useEffect(loadMoreTobacco, [tobaccoSiteNr]);

	const handleEdit = (id: number) => () => {
		console.log('######## handleEdit (' + id + ')');
	};

	return (
		<Card elevation={8} sx={{ margin: 1 }}>
			<CardHeader title='Zuletzt gerauchter Tabak'></CardHeader>
			<CardContent>
				<Grid container spacing={1} alignContent={'center'}>
					{lastSmokedTobaccos.length > 0 &&
						lastSmokedTobaccos.map((smokedTobacco) => {
							return (
								<Grid item key={smokedTobacco.id} xs={12} sm={6} md={4}>
									<SmokedTobaccoCard
										smokedTobacco={smokedTobacco}
										removeSmokedTobacco={removeSmokedTobacco}
										apiController={props.apiController}
									/>
								</Grid>
							);
						})}
				</Grid>
			</CardContent>
			<CardActionArea>
				<ExpandMoreIcon
					sx={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
					fontSize='large'
					color='primary'
					onClick={() => {
						if (isLoadingNewTobacco) return;
						console.log('Load more data');
						setTobaccoSiteNr(tobaccoSiteNr + 1);
					}}
				/>
			</CardActionArea>
		</Card>
	);
};

export default LastSmokedTobaccoCard;
