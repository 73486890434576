import React, { useState } from 'react';
import APIController from '../api/APIController';
import Loginscreen from './Screens/Loginscreen/Loginscreen';
import Mainscreen from './Screens/Mainscreen';

interface IProps {}

const AppStart: React.FC<IProps> = () => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [apiController, setAPIController] = useState(
		new APIController(setIsLoggedIn, '')
	);

	return (
		<>
			{!isLoggedIn && <Loginscreen apiController={apiController}></Loginscreen>}
			{isLoggedIn && <Mainscreen apiController={apiController}></Mainscreen>}
		</>
	);
};

export default AppStart;
