import axios from 'axios';
import SmokedTobacco from '../Models/SmokedTobacco';

export class APIController {
	API_ENDPOINT = 'https://api.hookahcentral.de';
	API_LOGINFUNCTION = '/rpc/login';
	API_USERINFO = '/user_info';
	API_SMOKEDTOBACCO = '/smoked_tobacco';
	API_TASTE = '/taste';
	API_TOBACCO = '/tobacco';
	API_LOGIN = '/rpc/login';
	LOAD_TOBACCOS_PER_PAGE = 5;

	setIsLoggedIn: Function;
	jwtToken: string = '';
	id = Math.random().toString();

	constructor(setIsLoggedIn: Function, jwtToken: string) {
		this.jwtToken = jwtToken;
		this.setIsLoggedIn = setIsLoggedIn;
		console.log('ID APIController: ' + this.id);
	}

	jwtTokenIsMissingException = () => {
		return {
			message: 'JWT token is missing - please login before',
			name: 'jwtTokenIsMissingException',
		};
	};

	//TODO: Proof of JWT-Token
	// proofToJWTToken() {
	// 	if (
	// 		this.jwtToken === null ||
	// 		this.jwtToken === undefined ||
	// 		this.jwtToken.trim() === ''
	// 	) {
	// 		throw new this.jwtTokenIsMissingException();
	// 	}
	// }

	createHeaders(additionalHeaders?: {}) {
		const config = {
			headers: {
				Authorization: `Bearer ${this.jwtToken}`,
				'Content-Type': 'application/json',
				...additionalHeaders,
			},
		};
		return config;
	}

	addParameterToURL(url: string, parameters: { [key: string]: string }) {
		let urlWithParam = url;

		if (parameters != null) {
			urlWithParam += '?';
			Object.keys(parameters).forEach((key) => {
				urlWithParam += key + '=' + parameters[key] + '&';
			});
		}

		return urlWithParam;
	}

	/**
	 *
	 * @param siteNr Begins by nr. 1 to ∞
	 * @param fncIfSuccess The function which will triggered, if the response is succeeded, with the data of response as param
	 * @param fncIfError The function which will triggered, if the response has an error code, with the error message
	 * @returns 5 smokedTobaccos, odered by descending smokedDate
	 */
	getSmokedTobacco(
		siteNr: number,
		fncIfSuccess: Function,
		fncIfError?: Function
	) {
		const url = this.API_ENDPOINT + this.API_SMOKEDTOBACCO;
		const parameters = {
			select:
				'id, smoked_on, tobacco(id, name, manufacturer(id, name), taste(id, name))',
			order: 'smoked_on.desc,id.asc',
		};

		const urlWithParams = this.addParameterToURL(url, parameters);

		const startingAt = (siteNr - 1) * this.LOAD_TOBACCOS_PER_PAGE;
		const endingAt =
			(siteNr - 1) * this.LOAD_TOBACCOS_PER_PAGE +
			(this.LOAD_TOBACCOS_PER_PAGE - 1);
		const additionalHeaders = {
			Range: startingAt + '-' + endingAt,
		};

		const headers = this.createHeaders(additionalHeaders);

		axios
			.get(urlWithParams, headers)
			.then((res) => {
				fncIfSuccess(res.data);
			})
			.catch((error) => {
				console.log(error);

				if (fncIfError != null) fncIfError(error);
			});
	}

	getUserInfo(setUser: Function) {
		const url = this.API_ENDPOINT + this.API_USERINFO;
		const headers = this.createHeaders();

		axios
			.get(url, headers)
			.then((res) => {
				setUser(res.data[0]);
			})
			.catch((error) => {
				console.log(error);
				return error;
			});
	}

	getTobaccos(fncIfSuccess: Function, fncIfError: Function) {
		const url = this.API_ENDPOINT + this.API_TOBACCO;
		const parameters = {
			select: 'id, name, manufacturer(id, name)',
		};

		const urlWithParams = this.addParameterToURL(url, parameters);

		const headers = this.createHeaders();

		axios
			.get(urlWithParams, headers)
			.then((response) => {
				fncIfSuccess(response.data);
			})
			.catch((response) => {
				fncIfError(response);
			});
	}

	postSmokedTobacco(body: {}, fncIfSuccess: Function, fncIfError: Function) {
		const url = this.API_ENDPOINT + this.API_SMOKEDTOBACCO;
		const headers = this.createHeaders({ Prefer: 'return=representation' }); // I will get the created entry back

		axios
			.post(url, body, headers)
			.then((res) => {
				fncIfSuccess(new SmokedTobacco(res.data[0]));
			})
			.catch((error) => {
				fncIfError(error);
			});
	}

	deleteSmokedTobacco(id: number) {
		const url = this.API_ENDPOINT + this.API_SMOKEDTOBACCO;
		const parameters = {
			id: 'eq.' + id.toString(),
		};
		const urlWithParams = this.addParameterToURL(url, parameters);

		const headers = this.createHeaders();

		axios.delete(urlWithParams, headers).then(() => {});
	}

	/**
	 *
	 * @param {*} email The mail from the user
	 * @param {*} password The password from the user
	 * @returns if successed JWT token, otherwise error message
	 */
	login(email: string, password: string) {
		var body = {
			email: email,
			pass: password,
		};

		axios
			.post(this.API_ENDPOINT + this.API_LOGIN, body)
			.then((response) => {
				this.jwtToken = response.data.token;
				this.setIsLoggedIn(true);
				return this.jwtToken;
			})
			.catch((error) => {
				return error;
			});
	}
}

export default APIController;
