import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import SmokedTobacco from '../../../Models/SmokedTobacco';
import Tobacco from '../../../Models/Tobacco';
import LastSmokedTobaccoCard from '../../Cards/LastSmokedTobaccoCard/LastSmokedTobaccoCard';
import APIController from '../../../api/APIController';

// import styles from './';

interface IProps {
	apiController: APIController;
}

/**
 * The homescreen (screen after login), displays last smoked tobaccos
 * @param {APIController} Object of type APIController
 * @returns
 */
const Homescreen: React.FC<IProps> = (props) => {
	return (
		<div>
			<LastSmokedTobaccoCard
				apiController={props.apiController}
			></LastSmokedTobaccoCard>
			<ToastContainer
				position='bottom-center'
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</div>
	);
};
export default Homescreen;
